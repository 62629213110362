import React from "react";

const Diff = () => (
    <div className="diff aspect-[16/9]">
        <div className="diff-item-1">
            <div className="bg-primary text-primary-content text-5xl font-black grid place-content-center">BUILDING THE FUTURE</div>
        </div>
        <div className="diff-item-2">
            <div className="bg-base-200 text-5xl font-black grid place-content-center">RESTORING THE PAST</div>
        </div>
        <div className="diff-resizer"></div>
    </div>
);

export default Diff;